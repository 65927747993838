import { FC, useContext, useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { api } from "../../api/Interceptor";
import { GlobalStateContext } from "../../context/GlobalStateContext";
import { resolverValidator } from "./dto/Resolver";
import { IFormInputs } from "../../types/login/LoginTypes";
import "./login.css";

const Login: FC = () => {
  const { user, setUser }: any = useContext(GlobalStateContext);
  const navigate = useNavigate();
  const [error,setError] = useState('')
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>({
    resolver: resolverValidator,
  });
  useEffect(() => {
    if (user.loggedIn) {
      navigate("/admin");
    }
  }, [navigate, user.loggedIn]);

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
   try {const response = await api.post("/auth/signin", {
      username: data.username,
      password: data.password,
    });
    if (response.data) {
      localStorage.setItem("token", response.data.accessToken);
      setUser({ loggedIn: true });
      navigate("/");
    }}catch(error){
      setError('Username or Password is incorrect')
    }
  };

  return (
    <div className="login-page">
      <div className="box-form">
        <h3 style={{ textAlign: "center" }}>Login</h3>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="mb-3">
            <label htmlFor="exampleInputUsername1" className="form-label">
              username
            </label>
            <input
              type="username"
              className="form-control"
              id="exampleInputUsername1"
              aria-describedby="usernameHelp"
              {...register("username")}
            />
            <small id="nameHelp" style={{ color: "rgb(167, 0, 0)" }}>
              <p>{errors.username?.message}</p>
            </small>
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputPassword1" className="form-label">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="exampleInputPassword1"
              {...register("password")}
            />
            <small id="nameHelp" style={{ color: "rgb(167, 0, 0)" }}>
              <p>{errors.password?.message}</p>
              <p>{error}</p>
            </small>
          </div>
          <button
            style={{ color: "white" }}
            type="submit"
            className="btn bg-black"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
