import { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { AxiosResponse } from "axios";

import { IFormInputs } from "../../types/create-single-certificate/CreateSingleCertificateTypes";
import { resolverValidator as resolver } from "./dto/Resolver";
import "./createSingleCertificate.css";
import { api } from "../../api/Interceptor";
import Spiner from "../../components/spiner-loader/Spiner";
import { dataForm } from "../../types/create-single-certificate/dataFormTypes";

const CreateSingleCertificate = () => {

  const [error, setError] = useState('');
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [path, setPath] = useState<string>("");
  const [token, setToken] = useState<string | null>("");
  const [Loading, setLoading] = useState<boolean>(false);
  const {
    reset,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>({
    resolver,
  });

  useEffect(() => {
    const valueToken = localStorage.getItem("token");
    setToken(valueToken);
  }, []);

  const onSubmit: SubmitHandler<IFormInputs> = (data: dataForm) => {
    try {
      if (data.date !== undefined) {
        data.date = new Date(data.date);
      }
      setLoading(true);
      api
        .post("/certificate", {
          certificates: [
            {
              serial_number: data.serialnumber,
              name: data.name,
              degree: data.degree,
              date: data.date,
            },
          ],
        })
        .then((res: AxiosResponse) => {
          setError('');
          api
            .post("/certificate/generate-pdf", {
              certificates: res.data,
            })
            .then((response: AxiosResponse) => {
              setError('')
              setPath(response.data.path);
              setLoading(false);
              setIsSubmit(true);
            })
            .catch(() => setLoading(false));
        })
        .catch((e) => {
          setLoading(false);
          setError("رقم الشهادة والتاريخ مسجلين مسبقا");
        });
    } catch (e) {
   
      setLoading(false);
    }
  };

  const handleClick = (e: any) => {
    if (e.target.classList.contains("full_page_layer")) {
      setIsSubmit(false);
    }
  };

  return (
    <div className="relative_page">
      <div className="wrapper-form">
        <h4>انشاء شهادة</h4>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="box-form"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="mb-3">
            <label htmlFor="exampleInputName1" className="form-label">
              Serial number
            </label>
            <input
              type="name"
              className="form-control"
              id="exampleInputname1"
              aria-describedby="nameHelp"
              {...register("serialnumber")}
            />
            <small id="nameHelp" style={{ color: "rgb(167, 0, 0)" }}>
              <p>{errors.serialnumber?.message}</p>
            </small>
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputName1" className="form-label">
              Name
            </label>
            <input
              type="name"
              className="form-control"
              id="exampleInputname1"
              aria-describedby="nameHelp"
              {...register("name")}
            />
            <small id="nameHelp" style={{ color: "rgb(167, 0, 0)" }}>
              <p>{errors.name?.message}</p>
            </small>
          </div>
          <div className="mb-3">
            <label htmlFor="disabledSelect" className="form-label">
              Degree
            </label>
            <select
              {...register("degree")}
              id="disabledSelect"
              className="form-select"
            >
              <option value="مستوفٍ">مستوفٍ</option>
              <option value="متوسط">متوسط</option>
              <option value="جيد">جيد</option>
              <option value="جيد جدا">جيد جدا</option>
              <option value="امتياز">امتياز</option>
            </select>
            <small id="nameHelp" style={{ color: "rgb(167, 0, 0)" }}>
              <p>{errors.degree?.message}</p>
            </small>
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputDate1" className="form-label">
              date
            </label>
            <input
              {...register("date")}
              type="date"
              className="form-control"
              id="exampleInputDate1"
            />
            <small id="nameHelp" style={{ color: "rgb(167, 0, 0)" }}>
              <p>{errors.date?.message}</p>
              <p style={{textAlign:'right'}}>{error}</p>
            </small>
          </div>
          <button
            style={{ color: "white" }}
            type="submit"
            className="btn bg-black"
          >
            create
          </button>
        </form>
        {Loading ? (
          <div className="full_page_layer">
            <Spiner />
          </div>
        ) : isSubmit ? (
          <div onClick={handleClick} className="full_page_layer">
            <div className="alert_download_box" style={{ textAlign: "center" }}>
              <h4 style={{ marginBottom: "20px" }}>
                تم انشاء الشهادة انقر للتحميل
              </h4>
              <a
                className={"download btn"}
                href={`${process.env.REACT_APP_API_URL}/certificate/download/${path}?token=${token}`}
                download
                target="_blank"
                onClick={() => 
                  {
                    setIsSubmit(false);
                    reset();
                  }
                }
                rel="noreferrer"
              >
                Download
              </a>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default CreateSingleCertificate;
