import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Spiner from "../../components/spiner-loader/Spiner";
import Welcome from "../../components/welcome/Welcome";
import "./authenticationCertificate.css";

function AuthenticationCertificate() {
  const { id } = useParams();
  const [data, setData] = useState<any>();
  const [Loading, setLoading] = useState<boolean>(false);
  const [Error, setError] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    setError(false);
    async function fetchData() {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/certificate/find/${id}`
      );
      if (response.data) {
        setData(response.data);
        setLoading(false);
      }
    }
    fetchData().catch(() => {
      setError(true);
      setLoading(false);
    });
  }, [id]);

  return (
    <div className="auth">
      <Welcome />
      {data ? (
        <div>
          <div className="auth_access">
            <div>{data.serial_number} :العدد</div>
            <div>الاسم: {data?.name}</div>
            <div>التقدير: {data?.degree}</div>
            <div>{data?.date} :التاريخ </div>
            {data.is_valid ? "" : <div>انتهت صلاحية الشهادة</div>}
            <Link
              style={{ marginTop: "10px" }}
              className="btn btn-primary"
              to="/"
            >
              العودة الى الصفحة الرئيسية
            </Link>
          </div>
        </div>
      ) : Loading ? (
        <div className="full_page_layer">
          <Spiner />
        </div>
      ) : Error ? (
        <div className="box_error">
          <div className="error_message">هذه الشهادة غير متوفرة</div>
          <Link
            style={{ marginTop: "10px" }}
            className="btn btn-primary"
            to="/"
          >
            العودة الى الصفحة الرئيسية
          </Link>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default AuthenticationCertificate;
