import { createContext, useEffect, useState } from "react";

export const GlobalStateContext = createContext({});

const AuthProvider = (props: any) => {
  let initValueGlobal: any = localStorage.getItem("GlobalData");
  const [GlobalData, setGlobalData] = useState(JSON.parse(initValueGlobal));
  let authentication: any = localStorage.getItem("token");
  const [user, setUser] = useState<any>({ loggedIn: false });
  
  useEffect(() => {
    if (authentication) {
      setUser({ loggedIn: true });
    } else { 
      setUser({ loggedIn: false });
    }
  }, [authentication])
  return (
    <GlobalStateContext.Provider value={{ GlobalData, setGlobalData,user,setUser }}>
      {props.children}
    </GlobalStateContext.Provider>
  );
};

export default AuthProvider;
