import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { useContext, useState } from "react";

import { api } from "../../api/Interceptor";
import { resolverValidator as resolver } from "./dto/Resolver";
import { IFormInputs } from "../../types/search-single-certificate/SearchSingleCertificateTypes";
import { GlobalStateContext } from "../../context/GlobalStateContext";
import Spiner from "../../components/spiner-loader/Spiner";

const SearchSingleCertificate = () => {
  let navigate = useNavigate();
  const [Loading, setLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const { setGlobalData }: any = useContext(GlobalStateContext);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>({
    resolver,
  });

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    setNotFound(false);
    setLoading(true);
    try {
      const response: AxiosResponse = await api.get(
        `/certificate/${data.number}?date=${data.date?.replace(/-/g, "/")}`
      );
      if (response.data) {
        setGlobalData(response.data);
        localStorage.setItem("GlobalData", JSON.stringify(response.data));
        setLoading(false);
        navigate(`/admin/view-single-certificate/${response.data.id}`);
      }
    } catch (e: any) {
      setNotFound(true);
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="wrapper-form">
        <h4>البحث عن شهادة</h4>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="box-form"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="mb-3">
            <label htmlFor="exampleInputName1" className="form-label">
              Number
            </label>
            <input
              type="number"
              className="form-control"
              id="exampleInputname1"
              aria-describedby="nameHelp"
              {...register("number")}
            />
            <small id="nameHelp" style={{ color: "rgb(167, 0, 0)" }}>
              <p>{errors.number?.message}</p>
            </small>
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputDate1" className="form-label">
              date
            </label>
            <input
              type="date"
              className="form-control"
              id="exampleInputDate1"
              {...register("date")}
            />
            <small id="nameHelp" style={{ color: "rgb(167, 0, 0)" }}>
              <p>{errors.date?.message}</p>
              <div>{notFound ? <div>Certificate not found</div> : ""}</div>
            </small>
          </div>
          <button
            style={{ color: "white" }}
            type="submit"
            className="btn bg-black"
          >
            search
          </button>
        </form>
      </div>
      {Loading ? (
        <div className="full_page_layer">
          <Spiner />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default SearchSingleCertificate;
