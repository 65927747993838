import logo from '../../images/home_page.png';
import "./welcome.css";

const Welcome = () => {
  return (
    <div>
      <div className="logo_auth">
        <img src={logo} alt="certificate" />
      </div>
      <div className="header_auth">
        <h4>جامعة الموصل </h4>
        <h5>التحقق من صحة الصدور</h5>
      </div>
    </div>
  );
};

export default Welcome;
