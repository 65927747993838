import { useForm, SubmitHandler } from "react-hook-form";
import { resolverValidator as resolver } from "./dto/Resolver";
import { IFormInputs } from "../../types/search-certificates/SearchCertificateTypes";
import { useNavigate } from "react-router-dom";

import "../create-single-certificate/createSingleCertificate.css";
import { useState } from "react";
import { api } from "../../api/Interceptor";

const SearchCertificate = () => {
  const navigate = useNavigate();
  const [NotFound, setNotFound] = useState<boolean>(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>({
    resolver,
  });

  const onSubmit: SubmitHandler<IFormInputs> = (data: any) => {
    setNotFound(false);
    api
      .get(`/certificate?date=${data.date.replace(/-/g, "/")}`)
      .then((response) => {
        navigate(
          `/admin/search-certificate/table/${response.data.results[0].date.replace(
            /\//g,
            "-"
          )}`
        );
      })
      .catch(() => {
        setNotFound(true);
      });
  };

  return (
    <div>
      <div className="wrapper-form">
        <h4>البحث عن عدة شهادات</h4>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="box-form"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="mb-3">
            <label htmlFor="exampleInputDate1" className="form-label">
              date
            </label>
            <input
              type="date"
              className="form-control"
              id="exampleInputDate1"
              {...register("date")}
            />
            <small id="nameHelp" style={{ color: "rgb(167, 0, 0)" }}>
              <p>{errors.date?.message}</p>
              <div>{NotFound && "Certificate not found"}</div>
            </small>
          </div>
          <button
            style={{ color: "white" }}
            type="submit"
            className="btn bg-black"
          >
            search
          </button>
        </form>
      </div>
    </div>
  );
};

export default SearchCertificate;
