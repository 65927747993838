import { IsNotEmpty } from "class-validator";

export class ValidationClassDto {
  @IsNotEmpty({
    message: "number is required",
  })
  number: number | undefined;

  @IsNotEmpty({
    message: "date is required",
  })
  date: string | undefined;
}
