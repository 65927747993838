import { IsNotEmpty,IsString } from "class-validator";

export class ValidationClassDto {
  @IsNotEmpty({
    message: "serial number is required",
  })
  serialnumber:number | undefined;

  @IsNotEmpty({
    message: "name is required",
  })
  @IsString({
    message: "name must be string",
  })
  name: string | undefined;

  @IsNotEmpty({
    message: "degree is required",
  })
  degree: string | undefined;

  @IsNotEmpty({
    message: "date is required",
  })
  date?: string | Date ;
  // date: Date | undefined;
}
