import { Link } from "react-router-dom";
import "./NotFound.css";
import NotFoundSvg from "./NotFoundSvg";

const NotFound = () => {
  return (
    <div className="not_found">
      <div>
        <NotFoundSvg />
      </div>
      <h2>هذه الصفحة غير متوفرة</h2>
      <Link className="back_to_home" to="/">العودة الى الصفحة الرئيسية</Link>
    </div>
  );
};

export default NotFound;
