import { IsNotEmpty, MinLength } from "class-validator";

export class ValidationClassDto {
  @MinLength(3, {
    message: "password must be 3 character in least",
  })
  @IsNotEmpty({
    message: "username is required",
  })
  oldPassword: string | undefined;

  @IsNotEmpty({
    message: "password is required",
  })
  newPassword: string | undefined;

  @IsNotEmpty({
    message: "password is required",
  })
  confirmPassword: "string" | undefined;

  
}
