import { FC, useContext, useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { api } from "../../api/Interceptor";
import { GlobalStateContext } from "../../context/GlobalStateContext";
import { resolverValidator } from "./dto/Resolver";
import { IFormInputs } from "../../types/login/LoginTypes";
import "./change-password.css";
import { ChangePassordFormInputs } from "../../types/change-password/changePasswordTypes";

const ChangePassword: FC = () => {
  const { setUser }: any = useContext(GlobalStateContext);
  const [error, setError] = useState('');
  const navigate = useNavigate();
 
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ChangePassordFormInputs>({
    resolver: resolverValidator,
  });
  
  const onSubmit: SubmitHandler<ChangePassordFormInputs> = async (data) => {
    console.log(data)
    if (data.confirmPassword != data.newPassword){
      setError("password must match")
      return false
    }
  try{
    const response = await api.patch("/auth/change-password", {
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
    });
     
    if (response.status === 200) {
      setUser({ loggedIn: false });
      localStorage.clear();
      navigate("/admin/login");
    } 
  }catch(error){
    setError('Old Password is incorrect')
  }

  };

  return (
    <div className="login-page">
      <div className="box-form">
        <h3 style={{ textAlign: "center" }}>تغيير كلمة السر</h3>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="mb-3">
            <label htmlFor="exampleInputUsername1" className="form-label">
              Old Password
            </label>
            <input
              type="password"
              className="form-control"
              id="exampleInputUsername1"
              aria-describedby="usernameHelp"
              {...register("oldPassword")}
            />
            <small id="nameHelp" style={{ color: "rgb(167, 0, 0)" }}>
              <p>{errors.oldPassword?.message}</p>
            </small>
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputPassword1" className="form-label">
              New Password
            </label>
            <input
                type="password"
              className="form-control"
              id="exampleInputPassword1"
              {...register("newPassword")}
            />
            <small id="nameHelp" style={{ color: "rgb(167, 0, 0)" }}>
              <p>{errors.newPassword?.message}</p>
            </small>
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputPassword1" className="form-label">
              Re-write New password
            </label>
            <input
             
             type="password"
              className="form-control"
              id="exampleInputPassword1"
              {...register("confirmPassword")}
            />
            <small id="nameHelp" style={{ color: "rgb(167, 0, 0)" }}>
              <p>{errors.confirmPassword?.message}</p>
              <p>{error}</p>
            </small>
          </div>
          <input
            style={{ color: "white" }}
            type="submit"
            className="btn bg-black"
          />
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
