// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Reem+Kufi&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@500&family=Reem+Kufi&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n  font-family: \"Noto Kufi Arabic\", sans-serif;\n}\n.body{\n  min-height: 100vh;\n}\n.wrapper-form {\n  padding-top: 60px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.box-form {\n  width: 50%;\n  min-width: 300px;\n}\n", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAGA;EACE,2CAA2C;AAC7C;AACA;EACE,iBAAiB;AACnB;AACA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,gBAAgB;AAClB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Reem+Kufi&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@500&family=Reem+Kufi&display=swap\");\n\n* {\n  font-family: \"Noto Kufi Arabic\", sans-serif;\n}\n.body{\n  min-height: 100vh;\n}\n.wrapper-form {\n  padding-top: 60px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.box-form {\n  width: 50%;\n  min-width: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
