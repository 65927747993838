import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Spiner from "../../components/spiner-loader/Spiner";
import Welcome from "../../components/welcome/Welcome";
import { IFormInputs } from "../../types/search-single-certificate/SearchSingleCertificateTypes";
import { resolverValidator as resolver } from "../../pages/search-single-certificate/dto/Resolver";
import { AxiosResponse } from "axios";
import { api } from "../../api/Interceptor";
import "./home.css";

const Home = () => {
  let navigate = useNavigate();
  const [Loading, setLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>({
    resolver,
  });
  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    setNotFound(false);
    setLoading(true);
    try {
      const response: AxiosResponse = await api.get(
        `/certificate/${data.number}?date=${data.date?.replace(/-/g, "/")}`
      );
      if (response.data) {
        setLoading(false);
        navigate(`/authentication/${response.data.id}`);
      }
    } catch (e: any) {
      setNotFound(true);
      setLoading(false);
    }
  };

  return (
    <div className="home_page">
      <Welcome />
      <div className="wrapper-form">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="box-form"
          style={{ display: "flex", flexDirection: "column",padding:"30px" ,borderRadius:"12px",
          boxShadow: "0px 6px 4px rgba(0, 0, 0, 0.04), 0px 10px 20px -4px rgba(0, 0, 0, 0.03)"
        }}
        >
          <div className="mb-3">
            <label htmlFor="exampleInputName1" className="form-label">
              Number
            </label>
            <input
              type="number"
              className="form-control"
              id="exampleInputname1"
              aria-describedby="nameHelp"
              {...register("number")}
            />
            <small id="nameHelp" style={{ color: "rgb(167, 0, 0)" }}>
              <p>{errors.number?.message}</p>
            </small>
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputDate1" className="form-label">
              date
            </label>
            <input
              type="date"
              className="form-control"
              id="exampleInputDate1"
              {...register("date")}
            />
            <small id="nameHelp" style={{ color: "rgb(167, 0, 0)" }}>
              <p>{errors.date?.message}</p>
              <div>{notFound ? <div>Certificate not found</div> : ""}</div>
            </small>
          </div>
          <button
            style={{ color: "white" }}
            type="submit"
            className="btn bg-black"
          >
            search
          </button>
        </form>
      </div>
      {Loading ? (
        <div className="full_page_layer">
          <Spiner />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Home;
