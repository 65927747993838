import { SetStateAction, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "react-responsive-pagination";
import { api } from "../../api/Interceptor";

import "./searchTable.css";
import { GlobalStateContext } from "../../context/GlobalStateContext";
import Spiner from "../../components/spiner-loader/Spiner";

const SearchTable = () => {
  const { date }: any = useParams();
  const navigate = useNavigate();
  const { setGlobalData }: any = useContext(GlobalStateContext);

  const [data, setData] = useState<any>("");
  const [checkBox, setCheckBox] = useState<null>();
  const [listItem, setListItem] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [Counter, setCounter] = useState<number>(1);
  const [totalItem, setTotalItem] = useState<number>(10);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [path, setPath] = useState("");
  const [token, setToken] = useState<string | null>("");
  const [Loading, setLoading] = useState<boolean>(false);
  
  const limit: number = 13;
  useEffect(() => {
    const valueToken: string | null = localStorage.getItem("token");
    setToken(valueToken);
    async function fetchData() {
      const response = await api.get(
        `/certificate?date=${date.replace(
          /-/g,
          "/"
        )}&limit=${limit}&page=${currentPage}`
      );
      if (response) {
        setTotalItem(Math.ceil(response.data.count / limit));

        setData(response.data.results);
      }
    }
    fetchData().catch();
  }, [currentPage, Counter, date, limit]);

  useEffect(() => {
    if (checkBox) {
      setListItem((prevState) => [...prevState, checkBox]);
    }
  }, [checkBox]);

  const handlePaginate = (page: SetStateAction<number>) => {
    setCurrentPage(page);
  };

  const handleClick = (e: any) => {
    if (e.target.classList.contains("full_page_layer")) {
      setIsSubmit(false);
    }
  };

  const handleChange = (e: any, data: any) => {
    const { name, checked } = e.target;
    if (checked) {
      if (name === "allSelect") {
        setListItem(data);
      } else {
        setCheckBox(data);
      }
    } else {
      if (name === "allSelect") {
        setListItem([]);
      } else {
        setListItem((listItem) =>
          listItem.filter((item: any) => item.id !== data.id)
        );
        setCheckBox(null);
      }
    }
  };

  const hanldeDelete = (id: any) => {
    try {
      api.delete(`/certificate/${id}`).then(() => setCounter(Counter + 1));
    } catch (e) {}
  };

  const handleEdit = (item: { id: any }) => {
    setLoading(true);
    setGlobalData(item);
    localStorage.setItem("GlobalData", JSON.stringify(item));
    navigate(`/admin/view-single-certificate/${item.id}`);
    setLoading(false);
  };

  const submitHandle = (e: { preventDefault: () => void }) => {
    setLoading(true);
    e.preventDefault();
    api
      .post(`/certificate/generate-pdf`, {
        certificates: listItem,
      })
      .then((response) => {
        setPath(response.data.path);
        setIsSubmit(true);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        padding: "40px",
        textAlign: "right",
      }}
    >
      <form className="table-big" onSubmit={submitHandle}>
        <div
          className="table_containter"
          style={{ backgroundColor: "#fcfcfc" }}
        >
          <h3 style={{ textAlign: "right" }}>قائمة الخريجين</h3>
          <div style={{ overflow: "auto" }}>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">حذف</th>
                  <th scope="col">تعديل</th>
                  <th scope="col">التقدير</th>
                  <th scope="col">الاسم</th>
                  <th scope="col">
                    <input
                      type="checkbox"
                      name="allSelect"
                      checked={listItem?.length === data?.length}
                      onChange={(e) => handleChange(e, data)}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((item: { id: any; degree?: any; name?: any }) => (
                    <tr key={item.id}>
                      <td
                        className="cursor"
                        onClick={() => hanldeDelete(item.id)}
                      >
                        حذف
                      </td>
                      <td className="cursor" onClick={() => handleEdit(item)}>
                        تعديل
                      </td>
                      <td>{item.degree}</td>
                      <td>{item.name}</td>
                      <th scope="row">
                        <input
                          name={data.name}
                          type="checkbox"
                          value={item.id}
                          checked={listItem?.some(
                            (el: any) => el?.id === item?.id
                          )}
                          id={item.id}
                          onChange={(e) => handleChange(e, item)}
                        />
                      </th>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div>
            <Pagination
              total={totalItem}
              current={currentPage}
              onPageChange={handlePaginate}
            />
          </div>
          <input
            className="btn btn-primary w-100"
            type="submit"
            value="submit"
          />
        </div>
      </form>
      {Loading ? (
        <div className="full_page_layer">
          <Spiner />
        </div>
      ) : isSubmit ? (
        <div onClick={handleClick} className="full_page_layer">
          <div className="alert_download_box" style={{ textAlign: "center" }}>
            <h4 style={{ marginBottom: "20px" }}>
              تم انشاء الشهادة انقر للتحميل
            </h4>
            <a
              className={"download btn"}
              href={`${process.env.REACT_APP_API_URL}/certificate/download/${path}`}
              download
              target="_blank"
              onClick={() => setIsSubmit(false)}
              rel="noreferrer"
            >
              Download
            </a>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default SearchTable;
