import { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalStateContext } from "../../context/GlobalStateContext";
import logo from "../../images/home_page.png";
import "./navbar.css";

const Navbar = () => {
  const { setUser }: any = useContext(GlobalStateContext);

  const logout = () => {
    setUser({ loggedIn: true });
    localStorage.clear();
  };
  return (
    <nav
      className="navbar navbar-expand-xl navbar-dark"
      style={{ backgroundColor: "#F79323" }}
    >
      <div
        className="container-fluid"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Link className="navbar-brand" to="/">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{ marginRight: "10px" }}
              src={logo}
              alt="logo"
              width="50px"
            />
            <div className="logo">جامعة الموصل</div>
          </div>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          style={{ flexGrow: "0" }}
          className="collapse navbar-collapse"
          id="navbarNav"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to="/admin">
                انشاء شهادة
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" to="/admin/create-certificates">
                انشاء عدة شهادات
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link active"
                to="/admin/search-single-certificate"
              >
                البحث عن شهادة
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" to="/admin/search-certificates">
                البحث عن عدة شهادات
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link active" to="/admin/change-password">
             تغيير الباسورد
             </Link>
            </li>
            <li onClick={() => logout()} className="nav-item">
              <Link className="nav-link active" to="/admin/login">
                تسجيل خروج
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
