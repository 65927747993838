import { FormEvent, useState } from "react";
import Dropzone from "react-dropzone";
import readXlsxFile from "read-excel-file";
import { AxiosResponse } from "axios";
import { api } from "../../api/Interceptor";
import Spiner from "../../components/spiner-loader/Spiner";
import { arrayCertificate } from "../../types/create-certificates/CreateCertificates";
import * as exelDateToJS from 'excel-date-to-js'


const CreateCertificates = () => {
  const [isDrag, setIsDrag] = useState<boolean>(false);
  const [dateWarning, setDateWarning] = useState<boolean>(false);
  const [fileUploaded, setFileUploaded] = useState<boolean>(false);
  const [fileUploadedWarning, setFileUploadedWarning] = useState<boolean>(false);
  const [dataFromFile, setDataFromFile] = useState<arrayCertificate>();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [Loading, setLoading] = useState<boolean>(false);
  const [path, setPath] = useState<string>("");

  const token: string | null = localStorage.getItem("token");

  let users: any[] = [];
  const [error,setError] = useState('')
  const handleChange = (file: File) => {
    readXlsxFile(file).then((rows) => {
      for (let i = 1; i < rows.length; i++) {
     
        users.push({
          serial_number: rows[i][0],
          date: exelDateToJS.getJsDateFromExcel(rows[i][1].toString()),
          name: rows[i][2],
          degree: rows[i][3],
        });
      }
    });

    setDataFromFile(users);
  };

  const handleClick = (e: any) => {
    if (e.target.classList.contains("full_page_layer")) {
      setIsSubmit(false);
    }
  };

  async function handleSubmit(e: FormEvent) {
  
    e.preventDefault();
    setLoading(true);
    setError("");
    fileUploaded ? setFileUploadedWarning(false) : setFileUploadedWarning(true);
      api
        .post(`/certificate`, {
          certificates: dataFromFile,
        })
        .then((res: AxiosResponse) => {
          api
            .post(`/certificate/generate-pdf`, {
              certificates: res.data,
            })
            .then((response) => {
              setPath(response.data.path);
              setLoading(false);
         
              setIsSubmit(true);
            }).catch(() => setLoading(false));
        }).catch((error) => 
        {
          setLoading(false);
          if(error.response.data.message[0] === "certificates must contain at least 1 elements"){
            setError('الملف لايحتوي على شهادات')
          }
        else{
          const certificateNumber = error.response.data.message[0].split('.')[1];
          setError(`لديك خطا في الشهادة رقم ${certificateNumber}`);
        }
        });
  }

  return (
    <div>
      <div
        style={{
          paddingTop: "60px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h4>انشاء عدة شهادات</h4>
        <form
          onSubmit={handleSubmit}
          className="box-form"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Dropzone
            maxFiles={1}
            accept=".xlsx"
            onDragLeave={() => setIsDrag(false)}
            onDragEnter={() => setIsDrag(true)}
            onDropAccepted={(acceptedFiles) => {
              setIsDrag(false);
              handleChange(acceptedFiles[0]);
              setFileUploaded(true);
            }}
            onDropRejected={() => {
              setIsDrag(false);
              setFileUploaded(false);
            }}
          >
            {({ getRootProps, getInputProps, acceptedFiles }) => (
              <section>
                <div
                  style={
                    isDrag
                      ? {
                        opacity: "50%",
                        border: "1px solid #999999",
                        borderRadius: "2px",
                        padding: "10px",
                      }
                      : {
                        border: "1px solid #999999",
                        borderRadius: "2px",
                        padding: "10px",
                      }
                  }
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <p style={{ textAlign: "center" }}>
                    <span style={{ color: "blue" }}>اضغط هنا </span>
                    او قم بادراج الشهادة
                  </p>
                  <ul style={{ width: "25%" }}>
                    {acceptedFiles.map((e, index) => (
                      <li key={index}>{e.name}</li>
                    ))}
                  </ul>
                </div>
              </section>
            )}
          </Dropzone>
          {
            <span
              role="alert"
              style={{
                display: fileUploadedWarning || dateWarning ? "block" : "none",
                color: "red",
                textAlign: "center",
                marginTop: "5px",
              }}
              aria-hidden={
                fileUploadedWarning || dateWarning ? "false" : "true"
              }
            >
              {fileUploadedWarning
                ? "Please upload your file"
                : ""}
            </span>
          }
         <small id="nameHelp" style={{ color: "rgb(167, 0, 0)" ,marginTop:"30px", textAlign:'right'}}>
              <p>{error}</p>
            </small>
          <button
            style={{ color: "white", marginTop: "10px" }}
            type="submit"
            className="btn bg-black"
          >
            create
          </button>
        </form>
        {Loading ? (
          <div className="full_page_layer">
            <Spiner />
          </div>
        ) : isSubmit ? (
          <div onClick={handleClick} className="full_page_layer">
            <div className="alert_download_box" style={{ textAlign: "center" }}>
              <h4 style={{ marginBottom: "20px" }}>
                تم انشاء الشهادات انقر للتحميل
              </h4>
              <a
                className={"download btn"}
                href={`${process.env.REACT_APP_API_URL}/certificate/download/${path}?token=${token}`}
                download
                target="_blank"
                onClick={() => {setIsSubmit(false);
                setLoading(false);
                    

                }}
                rel="noreferrer"
              >
                Download
              </a>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default CreateCertificates;
