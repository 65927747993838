import { Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import CreateCertificates from "./pages/create-certificates/CreateCertificates";
import CreateSingleCertificate from "./pages/create-single-certificate/CreateSingleCertificate";
import SearchCertificate from "./pages/search-certificates/SearchCertificate";
import SearchSingleCertificate from "./pages/search-single-certificate/SearchSingleCertificate";
import Login from "./pages/login/Login";
import ViewSingleCertificate from "./pages/view-singe-certificate/ViewSingleCertificate";
import SearchTable from "./pages/search-table/SearchTable";
import ProtectedRoutes from "./auth/ProtectedRoutes";
import AuthenticationCertificate from "./pages/authentication/AuthenticationCertificate";
import { useContext } from "react";
import { GlobalStateContext } from "./context/GlobalStateContext";
import NotFound from "./pages/404/NotFound";
import Home from "./pages/home-page/Home";
import ChangePassword from "./pages/change-password/change-password";
 
function App() {
  const { user }: any = useContext(GlobalStateContext);
  return (
    <div className="body">
      {user.loggedIn ? <Navbar /> : ""}
      <Routes>
        <Route path="/admin/login" element={<Login />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/admin" element={<CreateSingleCertificate />} />
          <Route
          path="/admin/change-password"
          element={<ChangePassword />}
          />
          <Route path="/admin/create-certificates" element={<CreateCertificates />} />
          <Route path="/admin/search-certificates" element={<SearchCertificate />} />
          <Route
            path="/admin/search-single-certificate"
            element={<SearchSingleCertificate />}
          />
        
          <Route

            path="/admin/view-single-certificate/:id"
            element={<ViewSingleCertificate />}
          />
         
          <Route
            path="/admin/search-certificate/table/:date"
            element={<SearchTable />}
          />
        </Route>
        
        <Route path="/" element={<Home />} />
        <Route
          path="/authentication/:id"
          element={<AuthenticationCertificate />}
        />
         
        <Route element={<NotFound />} path="*" />
      </Routes>
    </div>
  );
}

export default App;
