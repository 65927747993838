import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosResponse } from "axios";

import "./viewSingleCertificate.css";
import { api } from "../../api/Interceptor";
import { GlobalStateContext } from "../../context/GlobalStateContext";
import Spiner from "../../components/spiner-loader/Spiner";

const ViewSingleCertificate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { GlobalData, setGlobalData }: any = useContext(GlobalStateContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [edit, setIsEdit] = useState<boolean>(false);
  const [path, setPath] = useState<string>("");
  const token = localStorage.getItem("token");


  useEffect(() => {
    try {
      api
        .post(`/certificate/generate-pdf`, {
          certificates: [GlobalData],
        })
        .then((res: AxiosResponse) => {
          setPath(res.data.path);
          setLoading(false);
        });
    } catch (e) {}
    return () => {
      localStorage.removeItem("GlobalData");
    };
  }, [GlobalData, id]);

  const handleDone: any = () => {
    setLoading(true);
    api
      .patch(`/certificate/${id}`, {
        name: GlobalData.name,
        degree: GlobalData.degree,
        date: GlobalData.date,
      })
      .then(() => {
        setIsEdit(false);
        setLoading(false);
       
      })
      .catch((e) => {
        setIsEdit(false);
      });
  };

  const handleDelete = () => {
    try {
      api.delete(`/certificate/${id}`).then(() => navigate("/admin/search-single-certificate"));
    } catch (e) {}
  };

  const handleEdit = () => {
    setIsEdit(true);
  };

  const redirect = () => {
    navigate("/admin/search-single-certificate");
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setGlobalData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div>
      <div className="upload">
        <div style={{ textAlign: "center" }}>
          <h3>المعلومات</h3>
        </div>
        {loading ? (
          <div className="full_page_layer">
            <Spiner />
          </div>
        ) : edit ? (
          <div className="centerDiv" style={{ textAlign: "left" }}>
            <form
              onSubmit={handleDone}
              className="box-form"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                minWidth: "200px",
              }}
            >
              <div className="mb-3">
                <label htmlFor="exampleInputName1" className="form-label">
                  Name
                </label>
                <input
                  value={GlobalData.name}
                  type="name"
                  className="form-control"
                  id="exampleInputname1"
                  aria-describedby="nameHelp"
                  onChange={(e) => handleChange(e)}
                  name="name"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="disabledSelect" className="form-label">
                  Degree
                </label>
                <select
                  value={GlobalData.degree}
                  id="disabledSelect"
                  className="form-select"
                  onChange={(e) => handleChange(e)}
                  name="degree"
                >
                  <option value="مستو ٍف">مستو ٍف</option>
                  <option value="متوسط">متوسط</option>
                  <option value="جيد">جيد</option>
                  <option value="جيد جدا">جيد جدا</option>
                  <option value="امتياز">امتياز</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputDate1" className="form-label">
                  date
                </label>
                <input
                  value={GlobalData.date}
                  type="date"
                  className="form-control"
                  id="exampleInputDate1"
                  onChange={(e) => handleChange(e)}
                  name="date"
                />
              </div>
              <button
                style={{ color: "white" }}
                type="submit"
                className="btn bg-black"
              >
                Save
              </button>
            </form>
          </div>
        ) : (
          <div>
            <div className="details">
              <div>Name: {GlobalData?.name}</div>
              <div>Degree: {GlobalData?.degree}</div>
              <div>Date: {GlobalData?.date}</div>
            </div>
            <div className="buttons-wrapper">
              <button
                style={{ marginRight: "5px" }}
                className="btn btn-primary"
                onClick={redirect}
              >
                Go back
              </button>
              <button
                style={{ backgroundColor: "#e60808", marginRight: "5px" }}
                className="btn"
                onClick={handleDelete}
              >
                Delete
              </button>
              <button
                className="btn btn-warning"
                style={{ marginRight: "5px" }}
                onClick={handleEdit}
              >
                Edit
              </button>
              <a
                className="download btn btn-success"
                style={{ padding: "7px 12px", marginRight: "5px" }}
                href={`${process.env.REACT_APP_API_URL}/certificate/download/${path}?token=${token}`}
                download
                target="_blank"
                rel="noreferrer"
              >
                Download
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewSingleCertificate;
