import { css } from "@emotion/react";
import { HashLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Spiner = () => {
  let color: any = "#5DD7FF";
  return (
    <div className="sweet-loading">
      <HashLoader color={color} loading={true} css={override} size={70} />
    </div>
  );
};

export default Spiner;
