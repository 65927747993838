import { IsNotEmpty, MinLength } from "class-validator";

export class ValidationClassDto {
  @MinLength(3, {
    message: "username must be 3 character in least",
  })
  @IsNotEmpty({
    message: "username is required",
  })
  username: string | undefined;

  @IsNotEmpty({
    message: "password is required",
  })
  password: string | undefined;
}
